import firebase from 'firebase/compat/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore';
import Axios from 'axios'
import {getFirestore} from 'firebase/firestore'

// Initialize Firebase
let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

const app = firebase.initializeApp(config)

// const db = app.firestore()
const db = getFirestore(app)

export { Axios, db }